import { usePathname } from 'next/navigation'
import { useRecoilValue } from 'recoil'
import { appInterface } from '@store/isApp'

/** 새로고침 막기 인터페이스 호출 */
const useSetRefreshValue = () => {
	const pathname = usePathname()
	const nativeEvent = useRecoilValue(appInterface)
	const isPathShorts = pathname.includes('shorts')

	const setRefreshValue = (enableRefresh: boolean) =>
		nativeEvent({
			key: 'setRefreshValue',
			value: {
				enableRefresh: isPathShorts ? false : enableRefresh,
			},
		})

	return {
		setRefreshValue,
	}
}
export default useSetRefreshValue
